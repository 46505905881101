<template>
  <component
    :is="ImageComponent"
    :id="componentId"
    :alt="alt"
    class="prose-image"
    :height="height"
    :src="imageSrc"
    :width="width"
  />
</template>

<script setup lang="ts">
import { withTrailingSlash, withLeadingSlash, joinURL } from 'ufo'
import { useRuntimeConfig, computed } from '#imports'
import ImageComponent from '#build/mdc-image-component.mjs'
import type { ProseImgProps } from '#imports'

const {
  src = '',
  url = '',
  alt = '',
  width = undefined,
  height = undefined,
  border = 'none',
  borderRadius = null,
  padding = null,
  margin = null,
  display = 'inline',
  styles = '',
} = defineProps<ProseImgProps>()

const imageSrc = computed((): string => {
  // Utilize the `src` prop, but also check for `url` in case the user provides the wrong prop
  const customSrc = src || url

  if (customSrc?.startsWith('/') && !customSrc.startsWith('//')) {
    const _base = withLeadingSlash(withTrailingSlash(useRuntimeConfig().app.baseURL))
    if (_base !== '/' && !customSrc.startsWith(_base)) {
      return joinURL(_base, customSrc)
    }
  }
  return customSrc
})

// Inject any custom `styles` scoped by the `componentId` into the document head
const { componentId } = useCustomStyles(computed(() => styles), useAttrs().id as string)
</script>

<style scoped lang="scss">
.prose-image {
  border: v-bind('border');
  border-radius: v-bind('borderRadius');
  display: v-bind('display');
  margin: v-bind('margin');
  padding: v-bind('padding');
}
</style>
